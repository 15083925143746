define("discourse/plugins/discourse-translator/discourse/initializers/extend-for-translate-button", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/plugin-api", "I18n"], function (_exports, _ajax, _ajaxError, _pluginApi, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function translatePost(post) {
    return (0, _ajax.ajax)("/translator/translate", {
      type: "POST",
      data: {
        post_id: post.get("id")
      }
    }).then(function (res) {
      post.setProperties({
        translated_text: res.translation,
        detected_lang: res.detected_lang,
        topic_title: res.topic_title
      });
    });
  }
  function initializeTranslation(api) {
    const siteSettings = api.container.lookup("service:site-settings");
    const currentUser = api.getCurrentUser();
    if (!currentUser) {
      return;
    }
    if (!siteSettings.translator_enabled) {
      return;
    }
    api.includePostAttributes("translated_text", "detected_lang", "topic_title", "content_translated");
    api.addPostTransformCallback(post => {
      const detectedLang = post.detected_lang;
      const localeMapping = {
        en: {
          name: "English"
        },
        fr: {
          name: "Français"
        },
        de: {
          name: "Deutsch"
        }
      };
      const language = localeMapping[detectedLang]?.name || detectedLang;
      const currentLocale = _I18n.default.locale;
      const content_translated = post.content_translated;
      const id = post.id;
      if (!detectedLang || detectedLang === currentLocale) {
        return;
      }
      if (!content_translated) {
        return;
      }
      const header = `<div class="post-attribution">
        ${_I18n.default.t("translator.translated_from", {
        language: language,
        translator: siteSettings.translator
      })}
      </div>`;
      const translatedContentId = `translated-content-${id}`;
      if (!post.cooked.includes(translatedContentId)) {
        post.cooked = `<div id="${translatedContentId}">${header + post.cooked}</div>`;
      }
    });
    api.decorateWidget("post-menu:before", dec => {
      if (!dec.state.isTranslated) {
        return;
      }
      if (dec.state.isTranslating) {
        return dec.h("div.spinner.small");
      }
      let titleElements = [];
      if (dec.attrs.topic_title !== "") {
        titleElements = [dec.h("h2.topic-attribution", dec.attrs.topic_title)];
      }
      return dec.h("div.post-translation", [...titleElements, dec.cooked(dec.attrs.translated_text)]);
    });
    api.attachWidgetAction("post-menu", "translate", function () {
      const state = this.state;
      state.isTranslated = true;
      state.isTranslating = true;
      this.scheduleRerender();
      const post = this.findAncestorModel();
      if (post) {
        return translatePost(post).catch(error => {
          (0, _ajaxError.popupAjaxError)(error);
          state.isTranslating = false;
          state.isTranslated = false;
        }).finally(() => {
          state.isTranslating = false;
          const postElement = document.querySelector(`.regular.contents > .cooked > #translated-content-${post.id}`);
          if (postElement) {
            postElement.parentElement.style.display = 'none';
          }
        });
      }
    });
    api.attachWidgetAction("post-menu", "hideTranslation", function () {
      this.state.isTranslated = false;
      const post = this.findAncestorModel();
      if (post) {
        post.set("translated_text", "");
        const postElement = document.querySelector(`.regular.contents > .cooked > #translated-content-${post.id}`);
        if (postElement) {
          postElement.parentElement.style.display = 'block';
        }
      }
    });
    api.addPostMenuButton("translate", (attrs, state) => {
      const detectedLang = attrs.detected_lang;
      const content_translated = attrs.content_translated;
      const currentLocale = _I18n.default.locale;
      if (!detectedLang || detectedLang === currentLocale) {
        return;
      }
      if (!content_translated) {
        return;
      }
      const [action, title] = !state.isTranslated ? ["translate", "translator.view_translation"] : ["hideTranslation", "translator.hide_translation"];
      return {
        action,
        title,
        icon: "globe",
        position: "first",
        className: state.isTranslated ? "original" : "translated"
      };
    });
  }
  var _default = _exports.default = {
    name: "extend-for-translate-button",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.1", api => initializeTranslation(api));
    }
  };
});